import React, { PureComponent } from 'react'
import { withPrefix } from 'gatsby-link'
import browserLang from 'browser-lang'

import { languages } from './'

class Redirect extends PureComponent {

  constructor(props) {
    super(props)

    // During build time the "window" object does not exist
    if (typeof window !== 'undefined') {
      const languagesKeys = languages.map(language => language.value)
      const { pathname } = props.location

      const detectedLanguage =
        window.localStorage.getItem('lang') ||
        browserLang({
          languages: languagesKeys,
          fallback: 'en',
        })

      const newUrl = withPrefix(`/${detectedLanguage}${pathname}`)
      window.localStorage.setItem('lang', detectedLanguage)
      window.location.replace(newUrl)
    }
  }

  render() {
    return <div />
  }
}

export default Redirect
